exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-contribute-js": () => import("./../../../src/pages/contribute.js" /* webpackChunkName: "component---src-pages-contribute-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-declaration-js": () => import("./../../../src/pages/declaration.js" /* webpackChunkName: "component---src-pages-declaration-js" */),
  "component---src-pages-dmca-js": () => import("./../../../src/pages/dmca.js" /* webpackChunkName: "component---src-pages-dmca-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-retro-games-js": () => import("./../../../src/pages/retro-games.js" /* webpackChunkName: "component---src-pages-retro-games-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-templates-blog-page-js-content-file-path-src-blogs-10-free-retro-games-you-can-play-in-your-browser-right-now-mdx": () => import("./../../../src/templates/blog-page.js?__contentFilePath=/vercel/path0/src/blogs/10_free_retro_games_you_can_play_in_your_browser_right_now.mdx" /* webpackChunkName: "component---src-templates-blog-page-js-content-file-path-src-blogs-10-free-retro-games-you-can-play-in-your-browser-right-now-mdx" */),
  "component---src-templates-blog-page-js-content-file-path-src-blogs-from-doom-to-oregon-trail-a-history-of-the-most-influential-dos-games-mdx": () => import("./../../../src/templates/blog-page.js?__contentFilePath=/vercel/path0/src/blogs/from_doom_to_oregon_trail__a_history_of_the_most_influential_dos_games.mdx" /* webpackChunkName: "component---src-templates-blog-page-js-content-file-path-src-blogs-from-doom-to-oregon-trail-a-history-of-the-most-influential-dos-games-mdx" */),
  "component---src-templates-blog-page-js-content-file-path-src-blogs-history-of-strategy-games-mdx": () => import("./../../../src/templates/blog-page.js?__contentFilePath=/vercel/path0/src/blogs/history_of_strategy_games.mdx" /* webpackChunkName: "component---src-templates-blog-page-js-content-file-path-src-blogs-history-of-strategy-games-mdx" */),
  "component---src-templates-blog-page-js-content-file-path-src-blogs-how-to-play-the-oregon-trail-and-classic-dos-games-online-mdx": () => import("./../../../src/templates/blog-page.js?__contentFilePath=/vercel/path0/src/blogs/how-to-play-the-oregon-trail-and-classic-dos-games-online.mdx" /* webpackChunkName: "component---src-templates-blog-page-js-content-file-path-src-blogs-how-to-play-the-oregon-trail-and-classic-dos-games-online-mdx" */),
  "component---src-templates-blog-page-js-content-file-path-src-blogs-journey-back-in-time-exploring-best-dos-games-online-mdx": () => import("./../../../src/templates/blog-page.js?__contentFilePath=/vercel/path0/src/blogs/journey-back-in-time-exploring-best-dos-games-online.mdx" /* webpackChunkName: "component---src-templates-blog-page-js-content-file-path-src-blogs-journey-back-in-time-exploring-best-dos-games-online-mdx" */),
  "component---src-templates-blog-page-js-content-file-path-src-blogs-key-elements-of-strategy-games-mdx": () => import("./../../../src/templates/blog-page.js?__contentFilePath=/vercel/path0/src/blogs/key_elements_of_strategy_games.mdx" /* webpackChunkName: "component---src-templates-blog-page-js-content-file-path-src-blogs-key-elements-of-strategy-games-mdx" */),
  "component---src-templates-blog-page-js-content-file-path-src-blogs-legacy-of-prince-of-persia-dos-game-mdx": () => import("./../../../src/templates/blog-page.js?__contentFilePath=/vercel/path0/src/blogs/legacy-of-prince-of-persia-dos-game.mdx" /* webpackChunkName: "component---src-templates-blog-page-js-content-file-path-src-blogs-legacy-of-prince-of-persia-dos-game-mdx" */),
  "component---src-templates-blog-page-js-content-file-path-src-blogs-military-strategy-games-a-historical-overview-mdx": () => import("./../../../src/templates/blog-page.js?__contentFilePath=/vercel/path0/src/blogs/military_strategy_games__a_historical_overview.mdx" /* webpackChunkName: "component---src-templates-blog-page-js-content-file-path-src-blogs-military-strategy-games-a-historical-overview-mdx" */),
  "component---src-templates-blog-page-js-content-file-path-src-blogs-nostalgia-overload-15-vintage-computer-games-that-still-hold-up-today-mdx": () => import("./../../../src/templates/blog-page.js?__contentFilePath=/vercel/path0/src/blogs/nostalgia_overload__15_vintage_computer_games_that_still_hold_up_today.mdx" /* webpackChunkName: "component---src-templates-blog-page-js-content-file-path-src-blogs-nostalgia-overload-15-vintage-computer-games-that-still-hold-up-today-mdx" */),
  "component---src-templates-blog-page-js-content-file-path-src-blogs-rediscover-the-joy-of-classic-gaming-a-guide-to-the-best-retro-game-platforms-mdx": () => import("./../../../src/templates/blog-page.js?__contentFilePath=/vercel/path0/src/blogs/rediscover_the_joy_of_classic_gaming__a_guide_to_the_best_retro_game_platforms.mdx" /* webpackChunkName: "component---src-templates-blog-page-js-content-file-path-src-blogs-rediscover-the-joy-of-classic-gaming-a-guide-to-the-best-retro-game-platforms-mdx" */),
  "component---src-templates-blog-page-js-content-file-path-src-blogs-rediscovering-the-golden-age-of-pc-gaming-a-beginners-guide-to-playing-classic-dos-games-mdx": () => import("./../../../src/templates/blog-page.js?__contentFilePath=/vercel/path0/src/blogs/rediscovering_the_golden_age_of_pc_gaming__a_beginners_guide_to_playing_classic_dos_games.mdx" /* webpackChunkName: "component---src-templates-blog-page-js-content-file-path-src-blogs-rediscovering-the-golden-age-of-pc-gaming-a-beginners-guide-to-playing-classic-dos-games-mdx" */),
  "component---src-templates-blog-page-js-content-file-path-src-blogs-the-10-best-dos-games-of-all-time-and-where-to-play-them-today-mdx": () => import("./../../../src/templates/blog-page.js?__contentFilePath=/vercel/path0/src/blogs/the_10_best_dos_games_of_all_time_and_where_to_play_them_today.mdx" /* webpackChunkName: "component---src-templates-blog-page-js-content-file-path-src-blogs-the-10-best-dos-games-of-all-time-and-where-to-play-them-today-mdx" */),
  "component---src-templates-blog-page-js-content-file-path-src-blogs-the-5-best-online-platforms-for-enjoying-retro-and-classic-pc-games-mdx": () => import("./../../../src/templates/blog-page.js?__contentFilePath=/vercel/path0/src/blogs/the_5_best_online_platforms_for_enjoying_retro_and_classic_pc_games.mdx" /* webpackChunkName: "component---src-templates-blog-page-js-content-file-path-src-blogs-the-5-best-online-platforms-for-enjoying-retro-and-classic-pc-games-mdx" */),
  "component---src-templates-blog-page-js-content-file-path-src-blogs-top-10-dos-games-you-should-play-before-you-die-mdx": () => import("./../../../src/templates/blog-page.js?__contentFilePath=/vercel/path0/src/blogs/top-10-dos-games-you-should-play-before-you-die.mdx" /* webpackChunkName: "component---src-templates-blog-page-js-content-file-path-src-blogs-top-10-dos-games-you-should-play-before-you-die-mdx" */),
  "component---src-templates-blog-page-js-content-file-path-src-blogs-types-of-strategy-games-mdx": () => import("./../../../src/templates/blog-page.js?__contentFilePath=/vercel/path0/src/blogs/types_of_strategy_games.mdx" /* webpackChunkName: "component---src-templates-blog-page-js-content-file-path-src-blogs-types-of-strategy-games-mdx" */),
  "component---src-templates-blog-page-js-content-file-path-src-blogs-what-is-a-strategy-game-mdx": () => import("./../../../src/templates/blog-page.js?__contentFilePath=/vercel/path0/src/blogs/what_is_a_strategy_game.mdx" /* webpackChunkName: "component---src-templates-blog-page-js-content-file-path-src-blogs-what-is-a-strategy-game-mdx" */),
  "component---src-templates-game-category-page-js": () => import("./../../../src/templates/game-category-page.js" /* webpackChunkName: "component---src-templates-game-category-page-js" */),
  "component---src-templates-game-page-js": () => import("./../../../src/templates/game-page.js" /* webpackChunkName: "component---src-templates-game-page-js" */),
  "component---src-templates-game-saga-page-js": () => import("./../../../src/templates/game-saga-page.js" /* webpackChunkName: "component---src-templates-game-saga-page-js" */),
  "component---src-templates-play-game-page-js": () => import("./../../../src/templates/play-game-page.js" /* webpackChunkName: "component---src-templates-play-game-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */)
}

